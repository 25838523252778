/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* black and Goldenen theme */
:root {
  /* Main theme color */
  --portal-main-color: #9f00ff;
  --portal-theme-primary: #f6bd4b;
  --portal-theme-primary-filter-color: invert(74%) sepia(96%) saturate(396%)
    hue-rotate(332deg) brightness(101%) contrast(93%);
  --portal-theme-secondary: rgba(175, 151, 102, 0.199);
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  --text-color: white;
  /* Main background colors */
  --background-primary-color: black;
  --background-secondary-color: black;
  /* Sidebar Properties */
  --sidebars-text-color: white;
  --sidebars-active-color: #f6bd4b;
  /* --sidebars-hover-color: #b7849b; */
  --sidebars-background-color: #1d1c1d;
  /* Popup / Popover properties  */
  --popup-background-color: black;
  --popup-text-color: white;
  /* Element properties */
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: transparent;
  --input-background-secondary: #fff;
  --icons-color-allover: #f6bd4b;
  /* Button Properties */
  --button-background-color: #1c1c1c;
  --button-text-color: #f6bd4b;
  --button-outlined-color: #f6bd4b;
  --button-hover-color: "#f6bd4b";
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --datepicker-selected-color: #fff;
}
/*  in chrome this works */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
/* HINA KHAN IN DARK MODE CONVERSION STARTS FROM HERE */
body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.what-include {
  margin-top: 0px !important;
}
.secondary-background {
  background-color: var(--background-secondary-color);
}
.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  color: var(--text-primary-color);
}
.css-2tnm32.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}
::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

.css-18dh5wg-MuiTypography-root {
  color: var(--text-primary-color) !important;
}
.css-6obpvq-MuiTypography-root-MuiLink-root {
  color: var(--text-primary-color);
}
/* calender colors */
.MuiCalendarPicker-root {
  /* background-color: var(--popup-background-color) !important; */
  color: var(--input-text-color);
}
.MuiCalendarPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiCalendarPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}

.MuiClockPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root span {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}
.no-scroll::-webkit-scrollbar {
  /* display: none; */
}
.no-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.no-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.no-scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 50px;
}

/* Handle on hover */
.no-scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--portal-theme-primary-filter-color);
  cursor: pointer;
}

/* calender colors end */

/* card background */
/* .css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
} */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-ju84cc-MuiButtonBase-root-MuiMenuItem-root,
.css-1h0kuck-MuiButtonBase-root-MuiButton-root {
  color: var(--text-primary-color);
}
.css-1fuveia-MuiPaper-root-MuiPopover-paper,
.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
/* .css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  color: var(--sidebar-normal-color);
} */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}
.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-primary-color);
}
.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-1nuss9t,
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color) !important;
  -webkit-text-fill-color: var(--text-primary-color) !important;
}
/* fieldset {
  border-color: var(--portal-theme-secondary) !important;
}
fieldset:focus {
  border-color: var(--portal-theme-primary) !important;
} */
.svg-color svg,
.MuiCalendarPicker-root svg {
  color: #9f00ff;
}
.css-qua2zy-MuiToolbar-root {
  background: transparent !important;
}
.css-vpap0f-MuiButton-startIcon > *:nth-of-type(1) {
  font-size: 22px;
  /* color: var(--portal-main-color); */
}
/* calendar selected Color */
.css-14ewge7.Mui-selected,
.css-14ewge7.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}
.css-1qwrva7-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}
/* calender selected hover */
.css-2tnm32.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

/* .css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--portal-theme-primary);
} */
.css-1w4063c-MuiTypography-root,
.css-rq00hd-MuiTypography-root {
  cursor: pointer;
}
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color) !important;
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  /* background-color: var(--portal-theme-primary); */

  color: var(--background-primary-color);
}
.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}
.css-1gxhmaf-MuiListItemIcon-root {
  color: var(--portal-theme-primary) !important;
}
.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}
.css-mtn039 {
  /* background-color: #132144;
  color: white; */
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgba(0, 3, 82, 0.24) 0px 0px 2px 0px,
    rgba(0, 3, 82, 0.24) 0px 16px 32px -4px;
  border-radius: 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
.css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: #fff;
  color: var(--text-primary-color);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 3, 82, 0.24),
    0 16px 32px -4px rgba(0, 3, 82, 0.24);
  border-radius: 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}
.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none;
}
.css-1qro46r
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 2.5px 4px 2.5px 6px;
  color: black;
  /* black for light theme time zone in edit profile
  color: black; */
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-1nuss9t {
  color: var(--text-primary-color);
}
.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-lvwv31 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.css-pt151d {
  color: var(--portal-theme-primary);
}

.css-yzm7vx,
.css-184p1yy,
.css-12ff9sa,
.css-12ff9sa {
  color: var(--text-primary-color);
}
.review-card {
  background-color: #fff;
  color: var(--text-primary-color);
}

.css-k7695x-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
  /* z-index: 11111 !important; */
}
.css-3ioe56 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1hes0mo:hover {
  color: var(--portal-theme-primary);
}
.css-66zhem {
  background-color: var(--background-primary-color);
}
.css-ze1xs7 {
  color: #fff !important;
}
.mui-card-button .css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  color: var(--portal-theme-primary) !important;
}
.mui-card-button .css-vubbuv,
.program-lock-icon .css-vubbuv {
  fill: #f6bd4b;
  fill: white;
}
/* .MuiTableCell-root .MuiTableCell-body .MuiTableCell-sizeMedium .css-9cnftn-MuiTableCell-root{
  background-color: gold !important;
  
} */
.css-f0u5qy {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-12ff9sa {
  color: none;
}
.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}
.css-r13mc8 {
  color: var(--portal-main-color);
}
.centerAlign,
.centerAlign0 {
  align-items: center;
  justify-content: center;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mui-custom-card-content {
  padding: 15px 15px 20px;
}
.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}
.css-yxnldu {
  background-color: var(--sidebars-background-color);
}
/* .css-i4bv87-MuiSvgIcon-root {
  color: var(--button-text-color);
} */
.css-1bfvxec {
  color: var(--text-primary-color);
}
.css-yzm7vx {
  color: var(--text-primary-color);
  -webkit-text-fill-color: var(--text-primary-color) !important;
}
.css-vb81xk-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-primary-color);
}
.css-zuwxiq {
  color: var(--text-primary-color);
  -webkit-text-fill-color: var(--text-primary-color) !important;
}
.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}
.css-13d5z5v {
  color: var(--background-primary-color);
  /* background-image: url(../images/btn-img.png); */
}
.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
}
.css-q6wzhr {
  color: var(--portal-theme-primary);
}
.css-zuwxiq {
  color: var(--text-primary-color);
}
.css-2300kw {
  color: var(--text-primary-color);
}
.program-lock-icon .css-vubbuv {
  fill: var(--portal-theme-primary);
}
.css-vubbuv {
  fill: #9f00ff;
  /* fill: var(--portal-theme-primary); */
}
.css-4cxmk4,
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}
.card {
  background-color: #fff;
  border-radius: 15px;
}
.modal-theme {
  background-color: var(--sidebars-background-color);
}
.css-1xjb83h {
  color: var(--text-primary-color);
}
.css-rmquh4-MuiButtonBase-root-MuiChip-root {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
}
.css-1tir071-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color) !important;
}
.css-muvvuh {
  color: var(--text-primary-color);
}
.transaction-screen {
  background-color: var(--background-primary-color);
}
.css-2300kw.Mui-disabled {
  color: var(--text-primary-color);
}
.css-1tc40pv {
  color: var(--input-text-color);
}
/* .css-ijridm-MuiTypography-root {
  color: var(--portal-theme-primary);
} */
.css-3ioe56.Mui-expanded:last-of-type {
  background-color: var(--sidebars-background-color);
}
/* HINA KHAN IN DARK MODE CONVERSION ENDS HERE */

h1 {
  font-size: 40px;
  /* color: var(--portal-theme-primary); */
  color: #fff;
}
h2 {
  font-size: 30px;
  color: var(--portal-theme-primary);
}
h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}

.normal-text {
  font-size: 17px;
  font-weight: 500;
}
.text-secondary {
  color: var(--text-secondary-color) !important;
}

a:hover {
  text-decoration: none !important;
  color: var(--portal-main-color);
}

legend {
  float: unset;
}

.circular-progress {
  margin-left: 50%;
  margin-top: 20%;
}
.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
  color: black !important;
}
.notes {
  background-color: white !important;
  border: 1px solid #fff !important;
}
.heading {
  color: #fff;
}
.inputs-fields {
  /* background-color: var(--input-background-primary); */
  /* background-color: green; */
}
.form-control:focus {
  background-color: var(--textarea-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.form-control {
  border: none;
  color: black;
  font-size: 17px;
}
.programm-card {
  background-color: #fff;
}

.programm-card h3,
.lesson-heading {
  color: var(--portal-theme-primary);
}
.programm-card p span {
  color: var(--text-secondary-color);
}
.lesson-card h4 {
  color: var(--portal-theme-primary);
}
.textarea-color {
  background-color: var(--textarea-color);
}
.textarea-block textarea {
  color: var(--text-primary-color);
}
.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}
.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.custom-popover-icon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: var(--portal-theme-primary) !important;
}

.custom-popover li {
  z-index: 10;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 17px;
  color: var(--text-primary-color);
  font-weight: 500;
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary-color);
}
.embed-video h1,
.embed-video h2 {
  color: black;
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: var(--background-primary-color);
}

.small-contained-button {
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color) !important;
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color);
  border-radius: 5px;
  /* border: 1px solid var(--button-outlined-color); */
  border: 2px solid #9f00ff;
  font-size: 17px;
  font-weight: 600;
}
.small-contained-button-search {
  /* background-image: url(../images/btn-img.png); */
  background-color: #9f00ff !important;
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: #fff;
  border-radius: 5px;
  /* border: 1px solid var(--button-outlined-color); */
  border: 2px solid #fff;
  font-size: 17px;
  font-weight: 600;
}

.small-contained-button-checked {
  /* background-image: url(../images/btn-img.png); */
  background-color: rgb(207, 207, 207) !important;
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color);
  border-radius: 5px;
  /* border: 1px solid var(--button-outlined-color); */
  border: 2px solid #9f00ff;
  font-size: 17px;
  font-weight: 600;
}

.small-outlined-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--background-primary-color);
  border-radius: 5px;
  border: 2px solid var(--portal-theme-primary);
  font-size: 17px;
  font-weight: 600;
}
.table-space-wrapper {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.css-1fd40pz-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  /* background-color: var(--portal-main-color) !important; */
}
label .css-i4bv87-MuiSvgIcon-root {
  color: white !important;
  /* color: var(--portal-theme-primary) !important; */
}
.edit-profile-icon label .css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  color: var(--portal-theme-primary) !important;
}
label .css-vubbuv {
  color: white !important;
  fill: white !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: var(--portal-theme-primary);
}
.program-btn {
  text-align: left;
  margin-bottom: 20px;
}
.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 500;
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 17px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

.lesson-card {
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}
.payment-card .request-type {
  font-weight: 500;
}

.data-table {
  color: var(--text-primary-color);
  text-align: center;
}
.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}
.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}
.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid white;
}
.data-table .MuiDataGrid-row {
  border-bottom: 0.5px solid var(--portal-theme-primary);
}
.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
  font-size: 17px;
  font-weight: 500;
}
.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}
.loagin-button.Mui-disabled {
  background-color: var(--portal-theme-secondary) !important;
}
.loagin-button .MuiLoadingButton-loadingIndicator {
  color: var(--portal-theme-primary) !important;
}
.loagin-button:hover {
  background-color: var(--portal-theme-secondary);
}
.MuiDataGrid-iconSeparator {
  display: none !important;
}
.date-color {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.back-screen-button {
  background-color: #fff;
  color: #fff !important;
  margin-bottom: 10px;
}
.back-screen-button:hover {
  background-color: #d899ff !important;
  color: #fff !important;
  margin-bottom: 10px;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.memories-date p {
  color: var(--text-primary-color);
  margin-top: 15px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--portal-theme-secondary) !important;
}
.carousel-nav-button {
  background-color: var(--text-primary-color) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}
.css-d82q1s-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
}

.submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.submit-button-search {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: #9f00ff;
  border: 1px solid var(--button-outlined-color);
  color: #fff;

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.upload-button label {
  font-weight: 600;
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  position: relative;
}
.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}
.preview span:hover {
  cursor: pointer;
}
.new-memories .MuiFormControl-root {
  width: 100%;
}

/* .event-details {
  margin: 20px auto;
} */
.event-details h1 {
  font-size: 30px;
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}
#player {
  width: 100% !important;
}
.card-full-width {
  width: 100%;
}
.rating-stars-box {
  position: absolute;
  bottom: 0%;
}
.download-icon {
  margin-top: 12px;
}

.remove-border {
  border: none !important;
  margin: 0 !important;
  z-index: 1111 !important;
}
.remove-border svg {
  z-index: 1 !important;
}
.sidebar-icon svg {
  color: var(--button-text-color) !important;
}
.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}
.set-image-center img {
  margin: auto;
}

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
} */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

.wrapper {
  /* position: relative;
  padding-top: 56.25%;  */
  position: absolute;
  top: 0;
  left: 0;
  width: 73%;
  height: 100%;
  transform: translate(28%, 3%);
}

.dashboard-stats .player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.player {
  margin: auto;
}
.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.range-slider span {
  color: var(--portal-theme-primary);
}
.css-1e799a4-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}
.notes-input {
  padding: 15px;
  padding-right: 30px;
}

.slider-box {
  position: relative;
}
.join-now-btn {
  position: absolute;
  bottom: 25px;
}
.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: var(--portal-theme-primary);
}
.event-slider .slick-prev,
.event-slider .slick-next {
  z-index: 1;
}

.programm-svg:hover {
  cursor: pointer;
}
.review-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
}
.review-slider .slick-slide {
  flex: 0 0 auto;
  height: auto !important;
}
.review-slider .slick-slide > div {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--button-outlined-color);
  height: 100%;
  border-radius: 5px;
}
.review-slider .card-border {
  border: none;
}
.dashboard-circle {
  height: 565px;
}
/* chat style Support Tickets*/
.chat-main-card {
  background-color: var(--sidebars-background-color);
  box-shadow: none;
  color: var(--text-primary-color);
}
.chat-main-card .card-title {
  color: var(--portal-theme-primary);
}
.chat-title-head {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}
.chat-message-head {
  border-bottom: 1px solid gray;
}
.chat-title-body {
  border-right: 1px solid gray;
}
.chat-message-body {
  /* background-color: rgba(158, 163, 161, 0.08) !important; */
  /* border-top: 1px solid #282727; */
  position: relative;
  width: 100%;
}
.modal-content {
  background-color: var(--sidebars-background-color);
}
.mui-btn-close {
  /* color: var(--button-outlined-color) !important; */
  text-align: right;
}
.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
  color: var(--portal-main-color);
  background-color: #e9c5ff;
  z-index: 2;
}
.chat-main-card hr {
  margin: 0;
}
.chat-side-bar {
  padding-right: 0 !important;
}
.chat-title-body:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
  cursor: pointer;
}

.uiDataGrid-sortIcon {
  display: none;
}

.open-ticket-color {
  background-color: #007bff;
  color: var(--input-text-color);
  border-color: #007bff;
  width: 90px;
}
.solved-ticket-color {
  background-color: #28a745;
  color: var(--input-text-color);
  border-color: #28a745;
  width: 90px;
}
.waiting-ticket-color {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
  width: 90px;
}

.css-qen1a1-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.dx-sort {
  display: none !important;
}
.suppor-ticket-data-table .MuiDataGrid-row {
  cursor: pointer;
  border: 1px solid white;
}
.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}
.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid white !important;
  color: var(--portal-theme-primary) !important;
  width: 99% !important;
}
.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
  background-color: #fff !important;
  border-radius: 15px;
}
.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}
.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
  border: 1px solid white;
  overflow-x: hidden;
  width: 99% !important;
}
.custom-popover-box .remove-border {
  right: 5px;
  width: 30px;
  position: absolute;
  top: 40px;
}
.ticket_image_preview img:hover {
  cursor: pointer;
}
.SnackbarItem-action {
  display: inline;
  max-width: 80px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* for datepicker background on mobile */
.MuiDialog-paperScrollPaper {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}
/* end chat style Support Tickets*/

.associate-section {
  padding-left: 40px;
  color: var(--text-primary-color);
}

@media only screen and (max-width: 375px) {
  /* .custom-video-player {
    height: 100% !important;
  } */
  .lesson-heading {
    padding-top: 10px;
  }
  .card-spacing {
    margin-top: 0.75rem;
  }
  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .preview img {
    height: auto;
  }
  .fc-button-primary {
    padding: 3px !important;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
  .fc-toolbar-title {
    font-size: 16px !important;
  }
}
.mobile-margin {
  margin-top: 10px;
}
.MuiTableCell-root {
  color: var(--text-primary-color) !important;
  background-color: var(--sidebars-background-color) !important;
  font-weight: 500;
  font-size: 16px;
}
.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: var(--text-primary-color) !important;
  font-weight: 500 !important;
}
.MuiTableSortLabel-root,
.MuiTableCell-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiSelect-select {
  color: black;
}
.success {
  color: #229a16;
  background-color: rgba(84, 214, 44, 0.16);
}
.error {
  color: rgb(216, 55, 55);
  background-color: rgba(255, 72, 66, 0.16);
}
.cancel {
  color: white;
  background-color: rgba(216, 216, 216, 0.16);
}
.data-table {
  color: var(--text-primary-color);
  text-align: center;
}

.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}

.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid var(--portal-theme-secondary);
}
.data-table .MuiDataGrid-cell {
  font-size: 16px;
  font-weight: 500;
}

.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}

.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}
.MuiToolbar-root input {
  color: black;
}
.cursor-pointer:hover {
  color: var(--portal-theme-primary) !important;
}
.whole-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
/*=================================  full calender  =======================*/

.calender-events-box {
  margin-top: 10px;
}

.calender-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.fc-daygrid-day-frame {
  overflow: hidden;
}

/* .fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
} */

.calender-events-text {
  color: white;
}

.full-calendar .fc-day-today {
  /* background-color: var(--calendar-selected-box) !important; */
}
.full-calendar .fc-more-popover {
  background-color: var(--sidebars-active-color);
  z-index: 1;
}
.fc-toolbar-chunk {
  display: inline-flex;
}
.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: var(--portal-theme-primary);
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--text-primary-color);
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}
.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}
.dl-button {
  border: 1px solid #ccc5c5;
  padding: 5px;
  display: flex;
}
.dl-button2 {
  border: 1px solid var(--background-primary-color);
  padding: 5px;
  display: flex;
}

/* pinterest layout design */

.pinterest-card span {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
  padding: 0px 15px;
}

#columns {
  column-width: 280px;
  column-gap: 15px;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 0;
}

div#columns div {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 15px;
}
div.show-pointer div:hover {
  cursor: pointer;
}
.pinterest-card {
  position: relative;
}
div#columns figure {
  margin: 0 2px 15px;
  transition: opacity 0.4s ease-in-out;
  border-radius: 16px;
  display: inline-block;
  width: 99%;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

div#columns figure img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

div#columns figure p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 15px;
}

div#columns small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div#columns small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}
/* Custom styles for Next button */
.custom-next-arrow {
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -25px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

/* Custom styles for Previous button */
.custom-prev-arrow {
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 1;
  left: -25px; /* Adjust the left distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* div#columns:hover figure:not(:hover) {
  opacity: 0.4;
} */
.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}
.responce-messages .set-title-width {
  width: 57% !important;
}
.event-title-dot {
  height: 10px;
  width: 10px;
  margin-top: 4px;
  margin-right: 3px;
  border-radius: 50%;
}
.calendar-event-title {
  color: var(--text-color);
  margin-bottom: 5px;
  font-size: 13px;
}
.calendar-event-title:hover,
.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  color: #fff;
}
.fc .fc-col-header-cell-cushion:hover,
.fc .fc-daygrid-day-number:hover {
  color: #000;
}
.calendar-events-text {
  color: white;
}
.toollbar-group-buttons .small-contained-button {
  border-radius: 0;
  color: #fff;
  outline: none !important;
  background-color: transparent !important;
  padding: 0.4em 0.65em;
  font-size: 17px;
  font-weight: 600;
}

.toollbar-group-buttons .small-contained-button svg {
  color: #fff !important;
  font-size: 16px;
  fill: #fff !important;
}
.toollbar-group-buttons .not-selected-button {
  color: black;
}
.toollbar-group-buttons .small-contained-button:first-child {
  border-radius: 5px 0 0 5px;
}
.toollbar-group-buttons .small-contained-button:last-child {
  border-radius: 0 5px 5px 0;
}
.toollbar-group-buttons .selected-button {
  color: #fff;
}
.toollbar-group-buttons .not-selected-button {
  color: var(--input-text-color) !important;
}
.full-calendar-new-flow .fc-header-toolbar {
  display: none;
}
.calendar-card {
  background-color: var(--sidebars-background-color);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid var(--background-primary-color);
  border-radius: 13px;
}
.fc-daygrid-day .start_time_for_popup {
  display: none;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: absolute !important;
  /* margin-top: 30px; */
  top: 28%;
  width: 100%;
}
.fc-daygrid-event-harness-abs {
  /* visibility: visible !important; */
}
.calendar-week-card h4 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 2px;
}

.calendar-card .card-event-day {
  padding: 5px;
  color: var(--portal-theme-primary);
  text-align: center;
}

.calendar-card .calendar-dot {
  height: 13px;
  width: 13px;
  margin: 0 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.calendar-card .card-event-title {
  padding: 5px;
}

.calendar-card .card-event-title:hover {
  /* color: var(--portal-theme-primary); */
  color: var(--portal-main-color);
}

.calendar-card .card-event-title span {
  /* color: var(--text-secondary-color); */
  font-size: 14px;
  font-weight: 500;
  opacity: 0.9;
}

.calendar-card .week-event-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.calendar-card .week-event-title span {
  opacity: 0.7;
}
.general-popup-model {
  background-color: var(--sidebars-background-color) !important;
}
.event-description-title {
  color: var(--text-primary-color);
}
.event-description-details {
  color: var(--text-primary-color);
}
.date-color {
  color: gray !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  opacity: 0.8;
}
.suppor-ticket-data-table .date-color,
.event-details .date-color,
.date-color-new {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  opacity: 0.7;
}
.icon-event-detail {
  font-size: 18px;
  right: 1%;
  top: 10px;
  justify-content: end;
}

.icon-event-detail .close-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  background-color: rgb(72 121 207 / 30%);
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon:hover {
  background-color: rgb(72 121 207 / 30%);
}

.icon-event-detail .close-detail-icon svg,
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}
.perform-action-on {
  min-width: 400px;
  max-width: 100%;
}
.perform-action-on .css-ijridm-MuiTypography-root {
  color: var(--text-color);
}
.post-creator {
  display: flex;
}
.like-profile-image {
  position: relative;
}
.user-info-card .date-color {
  font-size: 12px !important;
}
@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }

  #columns figure {
    width: 100%;
  }
  .calendar-event-title {
    display: none;
  }
  .event-title-dot {
    margin-top: 0;
  }
}
@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }
  #columns figure {
    width: 100%;
  }
}
/* pinterest end */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}
.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}
.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

.quotes-heading {
  line-height: 1.235;
  margin-bottom: 25px;
}

.container h2,
.event-title h6 {
  color: #fff;
}
.event-title h6 {
  color: #9f00ff;
}
.dashboard-heading {
  color: var(--portal-theme-primary);
  margin: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  margin-bottom: 40px;
}
.add-button {
  text-align: right;
  padding-top: 0.25rem;
}
.drawer-section {
  max-width: 400px;
}
.MuiDrawer-paperAnchorRight {
  width: 400px !important;
}
.drawer-padding {
  padding: 0 !important;
}
.drawer-cross-btn {
  margin-top: 12px;
  padding: 6px;
  margin-bottom: 0px !important;
}
.drawer-cross-btn svg {
  font-size: 16px;
}
.custom-video-player iframe {
  height: 100% !important;
}
.react-audio-player {
  width: 100%;
}
button.disabled:hover {
  cursor: not-allowed;
}
.fc-theme-standard .fc-popover-header {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  background-color: rgb(0 0 0 / 67%);
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
  background: var(--background-primary-color);
}
.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
  transform: translate(1%, 30%);
}
/* .no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
  top: 50%;
  left: 60%;
  transform: translate(1%, 55%);
} */
.no-access-string p {
  margin-bottom: 0 !important;
  color: #fff !important;
  font-weight: 500;
}
.download-icon {
  text-align: end;
  margin-bottom: 20px;
}
.download-icon .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 40px;
  right: 10px;
}
.download-icon span {
  height: 20px !important;
  width: 20px !important;
}
.section-button button {
  /* display: block; */
  background-color: #145b6c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}
.dot-btn {
  position: absolute;
  right: 0;
  top: 5px;
}
.set-max-height {
  max-height: 200px !important;
  background-color: black;
}
.goal-statement-messages .tab {
  margin-top: 10px;
}

.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

/* .event-title {
  background-color: var(--sidebars-background-color);
} */
.SnackbarItem-message {
  padding-right: 40px !important;
}
.SnackbarItem-action svg,
.SnackbarItem-message svg {
  color: var(--input-text-color) !important;
}
swd-pin-field {
  display: flex;
  justify-content: space-between;
}
.responce-messages .set-title-width {
  width: 57% !important;
}
.forms-drawer .simplebar-wrapper {
  height: 100% !important;
}
.circle-image .set-title-width span {
  color: var(--text-primary-color);
}
.circle-images .MuiAvatar-root {
  width: 40px;
  height: 40px;
}
.circle-image .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.password-tooltip {
  border: 2px solid var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
}
.carousel-container a {
  text-decoration: none;
}
.simplebar-content .css-1gtfl7l {
  width: 100% !important;
  z-index: 1111111111;
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  box-shadow: none;
}
.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}
.recordings-card {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 11px 20px;
  border-radius: 15px;
}

audio::-webkit-media-controls-panel {
  width: 100% !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

.dashboard_description img {
  margin: 0px auto !important;
}
/* .dashboard_description p {
  color: var(--text-primary-color) !important;
} */

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

/* .sidebar-drawer {
  background-color: var(--background-primary-color);
} */

.date-picker button {
  background-color: red;
}

.goal_achieved_lower_text {
  display: none;
}
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 576px) {
  .add-button {
    text-align: center;
    padding-top: 0;
    margin-bottom: 30px;
  }
  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }
  .lesson-card-image {
    width: 100%;
  }
  .kims-reply-card .set-image-width {
    width: 23% !important;
  }
  .kims-reply-card .set-title-width {
    width: 57% !important;
  }
  .program-btn,
  .program-category-btn {
    text-align: center;
  }

  .goal_achieved_lower_text {
    display: block;
  }
  .goal_achieved_upper_text {
    display: none;
  }
  .dashboard-circle {
    height: 325px;
  }
  .css-qen1a1-MuiDataGrid-root {
    border-left: none !important;
    border-right: none !important;
    padding: 0 10px;
  }
  header img {
    width: 100% !important;
  }
  .SnackbarItem-message {
    max-width: 100%;
  }
  .support-ticket-button-contained,
  .support-ticket-button-outlined {
    margin-top: 20px;
  }
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }
  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

/* Comunity */

.new-community-page .banner-section {
  /* padding-top: 20px; */
}

.new-community-page .slick-arrow {
  display: none !important;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  height: 40px;
  width: 40px;
  border: 1px solid #ffff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 8px;
}

.score-row img {
  width: 30px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  width: 85%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: -6px;
  bottom: -3px;
}

.search-input input {
  background-color: #e9c5ff !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}

/* .search-input input:focus {
  border: none;
  background-color: #262626;
} */

.favourite-buttton-box button {
  background-color: var(--button-background-color) !important;
  /* border: 1px solid var(--button-outlined-color); */
  border: 2px solid #9f00ff;
  border-radius: 5px;
  color: var(--button-text-color);
  padding: 8px 16px;
  font-size: 17px;
  font-weight: 600;
}

.favourite-buttton-box svg {
  color: var(--button-text-color);
  font-size: 20px;
  margin-top: -3px;
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}
.input-with-profile img {
  /* width: 8%; */
  height: auto;
  border-radius: 50%;
  margin-right: 2%;
}
.input-with-profile .input-field {
  background-color: #262626;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
}

.search-input ::placeholder {
  color: #fff;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
}

.likes-post .MuiAvatarGroup-avatar {
  width: 12px;
  height: 12px;
  font-size: 11px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}
.comment-icons .MuiChip-root {
  border: none;
}

.likes-post .MuiAvatar-colorDefault {
  /* display: none !important; */
}

.comment-icons .MuiChip-root:hover {
  background-color: #262626;
}

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}

.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-community-page .img-back-border img {
  display: inline;
  width: 50px;
}
.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
}

/* success-wrapper  */
.new-community-page .success-wrapper {
  padding-top: 80px;
}

.new-community-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}
.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}
.score-right-side h5 {
  margin-bottom: 0.8rem;
}

.main-section {
  position: relative;
}

.main-section hr {
  margin: 7px auto;
}
.new-community-page .info-popup-box {
  position: absolute;
  left: -30px;
  top: -100px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 8px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
}
.new-community-page .wealth-wallet h5,
.new-community-page .wealth-wallet span {
  font-size: 10px;
}
.new-community-page .wealth-wallet span {
  color: #28a745;
}
.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p {
  font-size: 7px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
}

.increase-scale .star-icon svg {
  color: var(--button-text-color);
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 35px;
  width: 35px;
  font-size: 20px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}
.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  border: 1px solid white;
  height: auto;
}
.css-1lc5ta7 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 64px;
  background: transparent;
}
.css-r13mc8 {
  color: var(--portal-main-color);
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  font-size: 16px;
  color: #9f00ff;
  border-radius: 50%;
  font-weight: 600;
}

.create-post-title-form .preview img {
  width: 100%;
  height: auto;
}

.post-creator {
  display: flex;
}
.post-creator .creator-name h3 {
  /* font-size: 13px; */
}
.post-creator img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* margin-right: 15px; */
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}
.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-theme-secondary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: #cfcfcf !important;
  font-size: 20px;
  fill: #cfcfcf !important;
}

.video-image-selection .selected svg {
  color: var(--button-text-color);
  fill: var(--button-text-color);
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-secondary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.post-submit-btn {
  background-color: #fff;
  /* color: #000; */
  /* border: none !important; */
  padding: 5px 30px;
}
.thumbnail {
  position: relative;
  border: 1px solid black;
  background: black;
}

.thumbnail-progress {
  position: absolute;
  width: 100%;
  bottom: 1px;
}
.thumbnail-play-icon {
  position: absolute;
  left: 42%;
  top: 50%;
  transform: translateY(-50%);
  /* transform: translate(-50%); */
}
.pragram-acc {
  position: relative;
}
.pragram-acc-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.7;
  border-radius: 0px 0px 20px 20px;
}
.webinar-footer {
  background-color: rgb(23 56 96);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.event-dot {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 13px;
  margin: 0 6px;
  width: 13px;
}
@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }
  .new-community-page .join-today-btn {
    margin-top: 100px;
  }
  .input-with-profile .input-field {
    line-height: 47px;
  }
  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }
  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }
  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }
  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }
  .score-row img {
    width: 60px;
  }
  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }
  .show-on-desktop {
    display: none;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }
  .community-card-title h4 {
    font-size: 20px;
  }
  .new-community-page .activity-profile img {
    margin-top: 15px;
  }
  .community-card-title h6 {
    font-size: 18px;
  }
  .score img {
    display: inline;
  }
  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  .new-community-page .community-current-pods p {
    font-size: 16px;
  }
  .community-card-title h5 {
    font-size: 20px;
  }
  .new-community-page .upcoming-events p {
    font-size: 17px;
  }
  .total-points p {
    font-size: 30px;
  }
  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }
  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }
  .community-card-title .card-title {
    font-size: 20px;
  }
  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }
  .poster-name .card-title {
    font-size: 16px;
  }
  .poster-name .date-color {
    font-size: 15px !important;
  }
  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }
  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }
  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }
  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }
}

@media (max-width: 540px) {
  .new-community-page .banner-section,
  .slider {
    padding-top: 35px;
  }

  .new-community-page .success-wrapper {
    padding-top: 33px;
  }
  .new-community-page .slider-lower-buttons {
    text-align: center;
  }
  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }
  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }
  .likes-post {
    font-size: 11px;
  }
}

@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }
  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }
  .score-row img {
    width: 40px;
  }
  .input-with-profile img {
    /* width: 15%; */
  }
  .community-card-title h4 {
    font-size: 13px;
  }
  .community-card-title h6 {
    font-size: 14px;
  }
  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }
  .community-card-title h5 {
    font-size: 16px;
  }
  .new-community-page .community-current-pods p {
    font-size: 12px;
  }
  .new-community-page .upcoming-events p {
    font-size: 12px;
  }
  .favourite-buttton-box {
    margin-top: 15px;
  }
  .community-card-title .card-title {
    font-size: 14px;
  }
  .reduce-margin-from-para {
    font-size: 13px;
  }
  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}
.change-password-modal {
  display: flex;
  justify-content: space-between;
}
.image-border {
  border: 2px solid #cacaca;
}

.lesson-text {
  position: absolute;
  bottom: 13px;
  left: 14px;
  right: 0;
}
.lock-icon-color {
  color: #9f00ff !important;
  fill: #9f00ff !important;
}
.fc .fc-button-primary {
  text-transform: capitalize;
}
.recommended {
  font-size: 15px !important;
  color: rgb(175, 173, 173) !important;
}
.title {
  text-transform: capitalize;
}
.icon-player {
  width: 50px;
  height: 100px;
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}
.tox-notifications-container {
  visibility: hidden !important;
}
.menus-list {
  /* padding-top: 12px;
  padding-bottom: 12px;
  color: var(--input-text-color); */
  width: 100%;
  font-weight: 500 !important;
}
.video-player {
  position: relative;
  height: 0;
  padding-bottom: 38.25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.duration-wrapper {
  position: relative;
  padding-top: 37.25%;
  margin-left: 20%;
  /* Player ratio: 100 / (1280 / 720) */
}
.duration-wrapper .player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.MuiCircularProgress-root,
.css-1gwek9j-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-10447av.Mui-selected {
  color: #fff !important;
}
.main-color {
  color: #9f00ff !important;
}
.pods-lock-icon {
  width: 20px;
  height: 20px;
}
.main-card {
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 18px 5px 18px 5px;
}
.MuiTabs-indicator {
  background-color: #fff;
}
.pods-image-style {
  width: 70%;
  margin: auto;
}
.image-gallery-slide .image-gallery-image {
  /* width: 70%; */
  margin: auto;
}
.video-editor-portals iframe {
  width: 60% !important;
  border-radius: 15px;
}
@media (max-width: 768px) {
  .bg-mobile {
    background-image: none !important;
    background: #fff !important;
  }
}
.user-screens {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: auto;
  margin-top: 15%;
  align-items: center;
}
.user-card {
  width: 80px;
  height: 80px;
}
.MuiTablePagination-toolbar {
  align-items: baseline !important;
}
.menu-background-circle {
  height: 35px;
  width: 35px;
  border-radius: 51px;
  /* background-color: #145b6c29; */
}
.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}
.mui-without-bg-custom-table {
  /* background-color: #000; */
  border: 1px solid #fff;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: #000;
}

.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  border: 1px solid #8f8f8f;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-primary);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}
.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  font-size: 1rem;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 15px;
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.action-in-table-width {
  width: 120px;
}
.color-text {
  color: #fff; /* Label color on focus */
  /* opacity: 90%; */
}
.user-screen-title {
  background-color: #fff;
  /* border: 1px solid rgba(222, 187, 236, 0.5); */
  border-radius: 15px;
  padding: 2px 11px 2px 11px;
  color: #9f00ff;
  font-weight: bold;
}
.dynamite-diary .heading {
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  color: white;
}
.dynamite-diary .MuiDatePickerToolbar-root {
  display: none !important;
}
.dynamite-diary p {
  color: #ccc9c4;
  font-size: 12px;
}
.coint-detail {
  align-items: center;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 9px;
}
.coint-image img {
  width: 40px;
  height: auto;
}

.reminder-time-form input {
  color: white;
}

.reminder-time-form .submit-button {
  padding: 0px 20px;
}
.top-section-video iframe {
  border-radius: 15px;
  max-width: 100%;
}
.diary-para p {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9c4;
  margin-top: 20px;
}
.diray-title h4 {
  font-weight: 400 !important;
  color: white;
}
.field-container {
  width: 92%;
}
.textAreaAutoSize {
  width: 100%;
  border-radius: 8px;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  color: black;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}
.textarea-autosize {
  outline: none;
}
.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}
.cross-icon {
  padding-top: 1px;
  height: 25px;
  width: 26px;
  font-size: 13px;
  text-align: center;
  padding-top: 2px;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: 1%;
  top: 12px;
  cursor: pointer;
  top: 5px;
  /* z-index: 9999999999999999999999; */
}
.diary-icon-remove {
  color: #ffff00;
  fill: #ffff00;
  margin-bottom: 2px;
  cursor: pointer;
}
.diary-icon-add {
  fill: #fff;
  color: white;
  cursor: pointer;
}
.calender .MuiDialogActions-spacing {
  display: none !important;
}
.calender .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.5s;
}
.calender .MuiPickersCalendarHeader-label {
  font-weight: 500;
}
.calender {
  justify-content: center;
  /* margin-top: -18px; */
}
.full-open-calender .MuiCalendarPicker-root {
  background-color: transparent !important;
}

.textarea-autosize {
  outline: none;
}

.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}
.mini-cards {
  position: relative;
  width: 100%;
  border-radius: 15px;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: black;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.mini-cards h4 {
  font-weight: 400 !important;
}
.diary-font-weight {
  font-weight: 400 !important;
  justify-content: space-between;
}

.diary-font-weight h4 {
  font-weight: 400 !important;
}
.diary-first-heading {
  color: #000;
  font-weight: 500;
}
.wrapper-gratitude {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.player-gratitude {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* width */
.diary-notes::-webkit-scrollbar,
.vision_description ::-webkit-scrollbar,
.gratitude-text::-webkit-scrollbar,
.mini-cards::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mini-cards::-webkit-scrollbar-track,
.gratitude-text::-webkit-scrollbar-track,
.vision_description ::-webkit-scrollbar-track,
.diary-notes::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
.mini-cards::-webkit-scrollbar-thumb,
.gratitude-text::-webkit-scrollbar-thumb,
.vision_description ::-webkit-scrollbar-thumb,
.progress-card ::-webkit-scrollbar-thumb,
.diary-notes::-webkit-scrollbar-thumb {
  background: #e9c5ff;
  border-radius: 10px;
}
.progress-card ::-webkit-scrollbar-thumb {
  background: #9f00ff;
  border-radius: 10px;
}
/* Handle on hover */
.mini-cards::-webkit-scrollbar-thumb:hover,
.gratitude-text::-webkit-scrollbar-thumb:hover,
.vision_description ::-webkit-scrollbar-thumb:hover,
.diary-notes::-webkit-scrollbar-thumb:hover {
  background: #e9c5ff;
}

.gratitude-date {
  /* color: white; */
  background: #fff;
  border-radius: 3px;
  padding: 5px;
  display: inline-block;
  margin: 1px;
  width: 160px;
}
.gratitude-text {
  min-height: 101px;
  max-height: 101px;
  overflow-y: auto;
}
.group-card {
  background-color: #fff;
  border: 1px solid #808080;
  box-shadow: 0 0 3px 0 rgb(0 3 82 / 7%), 0 4px 12px -2px rgb(0 3 82 / 13%);
  /* cursor: pointer; */
  transition: all 0.5s;
  border-radius: 5px;
}
.groups-text-feeds {
  font-size: 1rem;
  font-weight: 400;
  color: #000 !important;
  /* text-transform: capitalize; */
  margin: auto;
}
/* width */
.groups-text-feeds::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.groups-text-feeds::-webkit-scrollbar-track {
  background: #e9c5ff;
  border-radius: 10px;
}

/* Handle */
.groups-text-feeds::-webkit-scrollbar-thumb {
  background: var(--portal-main-color);
  border-radius: 10px;
}

/* Handle on hover */
.groups-text-feeds::-webkit-scrollbar-thumb:hover {
  background: var(--portal-main-color);
}
.static-date-picker {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* padding-left: 3rem; */
}

.static-date-picker .MuiPickerStaticWrapper-content {
  background-color: #fff;
  border-radius: 15px;
}

.static-date-picker .css-epd502,
.static-date-picker .MuiCalendarPicker-root,
.static-date-picker .MuiDayPicker-header {
  width: 100%;
}

.static-date-picker .MuiDayPicker-header,
.static-date-picker .MuiDayPicker-weekContainer,
.static-date-picker .css-mvmu1r,
.static-date-picker .css-1n2mv2k,
.static-date-picker .css-1n2mv2k {
  justify-content: space-between !important;
}

.static-date-picker .MuiPickersCalendarHeader-root {
  padding-left: 16px !important;
  padding-right: 7px;
}
.static-date-picker .css-1dozdou,
.static-date-picker .css-1dozdou {
  padding-left: 16px !important;
  padding-right: 17px;
}
.static-date-picker .css-1cnkspq,
.static-date-picker .css-1cnkspq {
  min-height: 204px;
}
.static-date-picker .PrivatePickersFadeTransitionGroup-root {
  padding: 9px;
}

/* .static-date-picker .MuiDayPicker-header {
  padding-left: 15px;
} */

.static-date-picker .MuiPickersDay-root,
.static-date-picker .MuiDayPicker-weekDayLabel {
  font-size: 1rem !important;
}
@media (max-width: 912px) {
  .static-date-picker {
    padding-left: 0;
    margin-top: 25px;
  }
}
.recent {
  color: #fff;
}

.dropzone {
  /* width: 200px; */
  height: 300px;
  border: 2px dashed #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
  background-color: #fff;
  border-radius: 15px;
}
.drag-drop {
  color: var(--portal-theme-primary) !important;
  font-size: 15px !important;
}
.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 230px;
  color: white;
  font-family: "Montserrat";
  background-color: #fff;
  border: 2px dashed #000;
  border-radius: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.upload p {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
  font-size: 38px;
  transition: opacity 0.25s ease-in-out;
}
.upload.is-highlight {
  background-color: #fff;
  border: 2px dashed #000 !important;
  border-radius: 8px;
}

.upload.is-highlight p {
  opacity: 1;
}
.upload.is-drop p {
  opacity: 1;
}
.upload-div {
  background: #fff;
  /* height: 200px; */
  width: 100%;
  margin: 20px 0;
}

.upload-div img {
  object-fit: cover;
  border: 0;
  height: 210px !important;
  width: 100% !important;
}
.preview span {
  position: absolute;
  right: 0px;
  top: -6px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.images-wall {
  border-radius: 15px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  height: 630px;
  min-height: 630px;
  max-height: 630px;
  overflow-y: auto;
  color: white;
  font-family: "Montserrat";
  background-color: yellow;
  border: 1px solid yellow;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.emogi-wall {
  border-radius: 15px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  height: 330px;
  overflow-y: auto;
  color: white;
  font-family: "Montserrat";
  background-color: #fff;
  border: 1px solid yellow;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.images-wall::-webkit-scrollbar {
  width: 9px;
}

.images-wall::-webkit-scrollbar-corner,
.images-wall::-webkit-scrollbar-track {
  background: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.images-wall::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* .images-wall::-webkit-scrollbar-button:single-button {
  margin-top: 20px;
  background-color: #fff;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
.images-wall::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #000 transparent;
}

.images-wall::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #000 transparent;
}

.images-wall::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #000 transparent transparent transparent;
}

.images-wall::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #fff transparent transparent transparent;
}*/
.total-coins {
  margin-top: 5px;
  font-size: 21px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 4px;
}
.navbar-coins {
  padding: 2px 13px;
  display: flex;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 3, 82, 0.24) 0px 0px 2px 0px,
    rgba(0, 3, 82, 0.24) 0px 16px 32px -4px;
}
.MuiDataGrid-main {
  border-radius: 15px;
}
.player iframe {
  border-radius: 15px;
}
.replay-button {
  cursor: default !important;
}
.dropzone {
  position: relative;
  padding: 20px;
  text-align: center;
}

.drag-drop {
  font-size: 16px;
  margin-bottom: 10px;
}

.uploaded-image-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.uploaded-image {
  /* max-width: 100px; 
  height: auto; */
  border: 2px solid #cccccc;
}

.remove-image-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.remove-image-button svg {
  color: red;
  font-size: 20px;
}
.custom-search-field .MuiOutlinedInput-root {
  border-color: #fff; /* Border color */
}

.custom-search-field label.Mui-focused {
  color: #fff; /* Label color on focus */
  border-color: #fff !important;
}
.custom-search-field label {
  color: #fff; /* Label color on focus */
  border-color: #fff !important;
}
.wow-wall-text {
  margin-top: 3px;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600;
  text-transform: capitalize;
}
.app {
  display: flex;
  justify-content: space-between;
}

.calendar {
  width: 70%;
}

.day {
  padding: 10px;
  margin: 2px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 10px;
  margin-top: 20px;
}

.droppedEmoji {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.emoji-box {
  display: flex;
  flex-direction: column;
}

.emoji-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.emoji {
  margin: 5px;
  cursor: pointer;
}

.dropped-emojis {
  margin-top: 20px;
}
.audio-title {
  margin-bottom: 10px;
  align-self: flex-start;
  text-align: left;
  text-transform: capitalize;
}
.loading-preview {
  height: 500px;
  width: 700px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-preview-svg {
  color: var(--portal-main-color) !important;
}
.audio-resource {
  width: 500px;
  height: 110px;
}
.other-resource {
  width: 700px;
  height: 500px;
}
.embed-resource {
  width: 1100px;
  min-width: 1100px;
  overflow-x: auto;
  margin: 0 auto;
  height: 550px;
  align-content: center;
}
@media only screen and (max-width: 850px) {
  .audio-resource {
    width: 270px;
    height: auto;
  }
  .embed-resource {
    width: 500px;
    min-width: 500px;
    overflow-x: auto;
    margin: 0 auto;
  }
  .embed-resource iframe {
    width: 100%;
    min-width: 500px;
    overflow-x: auto;
    margin: 0 auto;
  }
  .other-resource {
    width: 500px;
    height: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .audio-resource {
    width: 270px;
    height: auto;
  }
  .embed-resource {
    width: 300px;
    min-width: 300px;
    overflow-x: auto;
    margin: 0 auto;
  }
  .embed-resource iframe {
    width: 100%;
    min-width: 300px;
    overflow-x: auto;
    margin: 0 auto;
  }
  .other-resource {
    width: 300px;
    height: 400px;
  }
}
.feedback-programme-header iframe {
  border-radius: 15px;
}
.meditaion-date {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 8px;
}
.meditation-videos-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
  color: var(--portal-main-color) !important;
  text-transform: capitalize;
  font-size: 15px !important;
}
.calender-date {
  font-size: 15px !important;
  color: black;
}
.MuiTab-root,
.css-yuj0vc-MuiTypography-root,
.css-1ri3awb {
  font-weight: 600;
  font-size: 1rem;
  color: #000;
}
.review-font {
  font-weight: 600;
  font-size: 17px;
}
.description-style {
  font-weight: 600;
  font-size: 17px;
  color: #fff;
}
.description-style-dark {
  font-weight: 600;
  font-size: 17px;
  color: #000;
}
.css-yuj0vc-MuiTypography-root,
.css-1ri3awb {
  color: #000;
}
.fix-weight {
  font-weight: 500;
}
.payment-font {
  font-weight: 500;
  font-size: 16px;
}
.static-date-picker .MuiCalendarPicker-root .Mui-selected {
  background-color: #e1b1ff !important;
  color: #000;
}
#fixedbutton {
  bottom: -19px;
  position: fixed;
  right: 20px;
  z-index: 11111111;
  margin-bottom: 10px;
}
.watched-content {
  display: flex;
  flex-direction: column;
}

.audio-card {
  background-color: yellow;
  /* box-shadow: 0 0 3px 0 rgb(0 3 82 / 7%), 0 4px 12px -2px rgb(0 3 82 / 13%); */
  cursor: default;
  transition: all 0.5s;
  border-radius: 15px;
}
.audio-text {
  font-size: 15px !important;
  font-weight: 600;
  color: #000;
  /* text-transform: capitalize; */
  margin: auto;
  margin-top: 11px;
}
.audio-icon {
  color: #000;
  background-color: yellow;
}
.audio-card .audio-text {
  height: 30px;
  overflow-y: auto;
}
.audio-text::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.audio-text::-webkit-scrollbar-track {
  background: #e9c5ff;
  border-radius: 10px;
}

/* Handle */
.audio-text::-webkit-scrollbar-thumb {
  background: var(--portal-main-color);
  border-radius: 10px;
}

/* Handle on hover */
.audio-text::-webkit-scrollbar-thumb:hover {
  background: var(--portal-main-color);
}
.drag-drop-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 95%;
  padding: 10px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.drag-drop-audio {
  margin: auto;
  text-align: center;
  margin-top: 3px;
}
.drag-drop-text {
  border: 2px dashed rgba(71, 66, 34, 0.3);
  color: #a9a39b !important;
  padding: 7px;
  border-radius: 7px;
  font-weight: 500;
}
.drop-audio-title {
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.audios-list .mini-cards {
  height: 189px;
  overflow-y: auto;
}
.audio-cross-icon {
  position: absolute;
  top: 11px;
  color: red !important;
  background-color: rgba(255, 0, 0, 0.1);
  /* background-color: #fff; */
  left: 117px;
}
@media only screen and (max-width: 500px) {
  .drag-drop-container {
    width: 90%;
  }
  .audio-cross-icon {
    left: 170px;
  }
}
/* Community Area */
.profile-cards {
  background-color: #9b41d2;
  border: 2px solid #fff;
  border-radius: 15px;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  cursor: pointer;
  transition: all 0.5s;
}
.poster-name .card-title {
  font-size: 14px;
}
.poster-name .date-color {
  font-size: 11px !important;
}
.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
  color: #fff;
}

.post-description a {
  color: var(--button-text-color);
  text-decoration: none;
  z-index: 1;
}
.main-section hr {
  margin: 7px auto;
}
.post-description span {
  z-index: 111;
  color: var(--button-text-color);
}

.inception .post-description {
  margin-bottom: 4px !important;
}

.inception .post-description span p {
  font-size: 14px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}
.inception .post-description span {
  font-size: 13px !important;
  font-weight: 400;
  color: var(--button-text-color);
  z-index: 0;
}
.live-event-comment-message {
  margin-top: 5px;
}
.favourite-videos-list,
.feed-image,
.post-description {
  cursor: pointer;
}
.feed-image img {
  width: 100%;
  object-fit: cover;
}

.single-comment-card {
  background-color: #af66db;
  padding: 10px;
  border-radius: 3px;
}
.single-comment-card .post-description {
  margin-bottom: 0;
}
.single-comment-card .card-title {
  font-size: 14px;
}
.inception .affirmation-dots .remove-border {
  top: 4px;
  right: -2px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}
.video-container {
  max-width: 100%;
  margin: 0 auto;
}
.live-comments {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #555;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.input-with-profile img {
  border-radius: 50% !important;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #fff;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  .poster-name .card-title {
    font-size: 16px;
  }

  .poster-name .date-color {
    font-size: 14px !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }
  .likes-post {
    justify-content: start;
    font-size: 13px;
  }

  .likes-post .MuiAvatarGroup-avatar {
    width: 12px;
    height: 12px;
    font-size: 11px;
  }

  .likes-post img {
    display: inline;
    height: 15px;
    width: 16px;
  }

  .likes-post svg {
    font-size: 20px;
  }
  .likes-post .MuiAvatar-colorDefault {
    display: none !important;
  }
  .likes-post span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
}
@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .btn-wrapper button {
    font-size: 13px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}
.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  /* overflow-y: scroll; */
}
.feed-detail-popup .profile-cards {
  height: 93vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}
.new-community-page .profile-cards {
  cursor: auto;
}
.emoji-picker-button-creat-post {
  position: absolute;
  top: 0;
  right: 0;
}
.emoji-picker-popup {
  width: 300px;
}

.emoji-picker-popup em-emoji-picker {
  height: 200px;
  width: 300px;
}
.create-post-images span {
  right: 13px;
}
.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  margin: 0;
}
.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}
.poster-logo img {
  width: 45px;
}
.post-detail-image iframe {
  width: 100% !important;
}
.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}
.dynamite-level-image {
  height: 30px !important;
  width: 34px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
}
.clear-both {
  clear: both;
}
.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}
.comment-icons img {
  width: 16px;
  height: 16px;
}
.comment-icons {
  padding: 0px;
}
.user-info-card .date-color {
  font-size: 12px !important;
}
.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}
.tiny-like-icon-notification img {
  width: 16px !important;
}

.likes-model-tabs {
  padding: 10px;
  color: var(--portal-main-color);
}

.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}

.show-all-likes-box {
  overflow: hidden;
}
.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 33px;
  width: 33px;
  font-size: 20px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #fff;
}
.post-detail-box {
  position: relative;
}
.show-all-likes-tabs img {
  width: 16px;
}
.feed-activity-box {
  max-height: 350px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed-activity-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed-activity-box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.load-more-text {
  cursor: pointer;
}
.detail-page-box .profile-main-comments {
  height: 210px;
  overflow: auto;
}

.popup-detail-box .profile-main-comments {
  height: 315px;
  overflow: auto;
}
.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}
.comment-submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);
  padding: 4px 15px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}
.like-reply-box span {
  color: #000;
  font-weight: 600;
}
.liked-comment {
  color: #fff !important;
}
.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  /* border: 1px solid #333232; */
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
  width: 20px;
  height: 18px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  /* border: 1px solid #333232; */
  border-radius: 50%;
  padding: 0px 3px;
  width: 29px;
  height: 18px;
}
.live-event-comment-message .comment-like-heart-image,
.live-event-comment-message .comment-like-heart-count {
  max-height: 20px;
}
.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.event-comment-like-box .comment-like-heart {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.date-portals {
  color: #858585;
  padding-left: 12px;
  font-size: 15px;
}
.date-portals-title {
  color: white;
  margin-right: 5px;
}

.popup-detail-box {
  height: 100vh;
  overflow: hidden;
}
.detail-page-box {
  height: 90vh;
  overflow: auto;
}
.css-fwfldc-MuiInputBase-root-MuiOutlinedInput-root,
.css-qgdod3 {
  color: white;
}
.feed-video {
  margin: 110px !important;
}
.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
  /* background-color: #fff; */
}
.comment-icons .MuiChip-root {
  border: none;
}
.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}
.comment-icons .MuiChip-root:hover {
  background-color: #c38de4;
}
.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}
.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}
.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  color: #9f00ff;
  font-size: 16px;
  border-radius: 50%;
  font-weight: 600;
}
.modal-theme {
  background-color: var(--sidebars-background-color);
}
.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: -10px;
  cursor: pointer;
  top: -10px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}

.create-post-title-box h2 {
  color: #000;
}
.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--input-text-color);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-main-color);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: #cfcfcf !important;
  font-size: 20px;
  fill: #cfcfcf !important;
}
.video-image-selection .selected svg {
  color: #fff;
  fill: #fff;
}
.post-submit-btn {
  padding: 5px 30px;
}
.event-chat-emoji-picker-button img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.event-chat-emoji-picker-button {
  padding: 6px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  width: 35px;
}
.open-create-post-card .profile-cards {
  min-height: auto;
}
.new-community-page .wealth-wallet {
  position: relative;
}
.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
.new-community-page .wealth-wallet h5,
.new-community-page .wealth-wallet span {
  font-size: 10px;
}
.new-community-page .wealth-wallet span {
  color: #28a745;
}
.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}
.post-by .MuiChip-root {
  color: #9f00ff !important;
  height: 16px;
  font-size: 10px;
  margin-top: 0px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: #fff;
}
/* .post-by {
  justify-content: space-between;
} */
.new-community-page .slick-arrow {
  display: none !important;
}
.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
}
.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
}
.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}
.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}
.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  width: 85%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}
.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}
.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}
.new-community-page .img-back-border img {
  display: inline;
  width: 50px;
}
.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
}
.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 28px;
  height: 28px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}

.profile-comments.inception {
  background-color: var(--sidebars-background-color);
  color: #fff;
}
.image-slider {
  margin: 21px 50px 10px 50px !important;
}
.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  /* color: var(--button-text-color);
  background-color: var(--portal-theme-secondary); */
  color: var(--button-text-color);
  background-color: #fff;
  border-radius: 50%;
}
.image-slider .image-thumbnails {
  display: flex;
  justify-content: center;
}

.image-slider .main-image img {
  max-height: 400px;
}
.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}
.css-105tfim {
  color: var(--portal-theme-primary);
}
.detail-page .image-slider {
  margin: 29px 50px 10px 50px !important;
}

.image-gallery-custom-left-nav,
.image-gallery-custom-right-nav {
  color: var(--button-text-color);
  background-color: #fff !important;
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
}

.image-gallery-custom-left-nav {
  left: -50px;
}

.image-gallery-custom-right-nav {
  right: -50px;
}

.image-gallery-custom-left-nav img,
.image-gallery-custom-right-nav img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  padding: 6px;
}

.profile-comments .date-color {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 0.9;
}
.feed-mini-cards {
  width: 100%;
  border-radius: 15px;
  background: #9b41d2;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: black;
  font-size: 15px;
  border: 2px solid #fff;
  height: 190px;
  text-align: center;
}

.feed-mini-cards h3 {
  margin-top: 2px;
  color: #feee00;
}
.profile-cards hr {
  border: none;
  height: 2px;
  background: #feee00;
  opacity: 1;
}
.upload-video .MuiChip-root:hover {
  background-color: #c38de4;
}
.show-all-likes-box .MuiTabs-indicator {
  background-color: var(--portal-main-color);
}
.profile-cards .user-name {
  color: #fff;
  text-transform: capitalize;
}
.show-all-likes-box .post-by .MuiChip-root {
  color: #fff !important;
  background-color: var(--portal-main-color);
}
/* hide image thumbnails */
/* .image-gallery-thumbnails-wrapper {
  display: none;
} */
.radius-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.radius-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.feed-level .custom-popover-icon,
.single-comment-card-dots .custom-popover-icon {
  color: #fff !important;
}
.single-comment-card-dots .css-vubbuv,
.feed-level .css-vubbuv {
  fill: #fff !important;
}
.profile-comments .new-memories {
  background-color: #9b41d2;
}
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--sidebars-background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.css-106uvt4-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-ksa4da-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-1gl5hlk.Mui-selected,
.css-1uvpdhh.Mui-selected {
  color: #fff;
}
.creator-name {
  font-size: 1.2rem !important;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
  color: var(--portal-main-color);
  text-transform: capitalize;
}
.upload-button label .css-i4bv87-MuiSvgIcon-root {
  color: #9f00ff !important;
}
.video-url-field .form-control {
  border: 1px solid #ced4da;
}

.notifications-circle {
  color: var(--portal-main-color);
  fill: var(--portal-main-color);
  font-size: 12px;
  margin-right: 5px;
}

.notifications-circle-seen {
  color: #f8f9f9;
  fill: #f8f9f9;
}
.notification-title {
  color: var(--portal-theme-primary);
}
.notification-item-box {
  display: flex;
  margin-bottom: 1px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.notification-item:hover .notifications-delete-button,
.notification-item-unread:hover .notifications-delete-button {
  visibility: visible;
}
.notification-item,
.notification-item:hover {
  background-color: #fafbfb !important;
}
.notification-item-unread,
.notification-item-unread:hover {
  background-color: #fafbfb !important;
}

.notification-item-unread span {
  color: #000;
}
.notification-icons-btn {
  background-color: var(--portal-theme-secondary);
  color: #fff;
}
.notification-popover-paper {
  top: 5px !important;
}
.notification-popover {
  background-color: var(--sidebars-background-color);
  color: #fff;
}
.notification-popover-loader {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
.video-url-field .form-control {
  border: 1px solid #ced4da;
}

.css-k41cr7.Mui-selected {
  color: #fff;
}
.page-detail-box .profile-main-comments {
  height: 260px;
  overflow: auto;
}
.css-1ubhkh0-MuiAvatar-root-MuiAvatarGroup-avatar,
.css-1etjxen {
  width: 20px !important;
  height: 20px !important;
  font-size: 11px !important;
  color: #000 !important;
}
.affirmations .mini-cards {
  height: 200px;
  overflow-y: auto;
}
.tools .css-i4bv87-MuiSvgIcon-root,
.tools .css-vubbuv {
  fill: #9f00ff !important;
}
.portal-color {
  color: #9f00ff !important;
}
.mini-cards .no-access-string {
  width: 100% !important;
  text-align: center;
  padding: 1px !important;
  transform: translate(0%, 0%) !important;
}
.image-div {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.image-div img {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
  border-radius: 15px;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: white;
  /* cursor: pointer; */
}
.started .play-icon {
  top: 45%;
}
.view-challenge-button-box {
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
.visioneering-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .view-challenge-button-box {
    position: static;
    /* margin-top: 10px; */
  }
  .button-container {
    justify-content: flex-end;
    /* flex-direction: column; */
  }

  .button-container .small-contained-button {
    /* margin-top: 10px; */
  }
}
.small-contained-button {
}

.assetssment-para h3 {
  font-size: 24px;
  color: #fff;
  /* font-family: "Inter", sans-serif; */
  margin-bottom: 16px !important;
}

.assetssment-para p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 60px;
}
.challenges hr {
  border: none;
  height: 3px;
  background: #feee00;
  opacity: 1;
}
.challenge-box .image-div {
  box-shadow: none;
}
.small-contained-button {
  /* background-color: var(--button-background-color) !important;
  padding: 4px 10px;
  color: var(--button-text-color);
  border-radius: 5px;
  border: 2px solid #9f00ff;
  font-size: 13px;
  font-weight: 600; */
}
.notes-buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.max-images {
  margin-bottom: 0px;
  font-size: 12px;
  opacity: 0.9;
  color: #8a8a8a;
  margin-left: 4px;
}
.upload-button-notes {
  position: relative;
}
.upload-button-notes label {
  font-weight: 600;
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 136px;
  /* width: 76px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
/* .notes-images span {
  right: 53px;
} */
.notes-text {
  color: #fff;
}
.vision-canvas {
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
}
.vision_description {
  min-height: 321px;
  max-height: 321px;
  overflow-y: auto;
}
.vision-upload-icon svg {
  background-color: #e9c5ff;
  border-radius: 50%;
  padding: 3px;
  width: 36px;
  height: 36px;
  color: #9f00ff;
}
.vision-upload-icon p {
  font-size: 10px;
  color: #000;
  font-weight: 600;
}
.vision-upload-icon {
  /* margin-top: 22px; */
  /* margin-right: 10px; */
  /* margin-left: 17px; */
}
.image-container {
  position: relative;
  display: inline-block;
  margin-right: 8px; /* Adjust margin as needed */
}

.delete-icon {
  position: absolute;
  top: -2px;
  right: 0px;
  cursor: pointer;
  background-color: red;
  color: #fff;
  border-radius: 50%;
}
.scrollable-container {
  /* width: 300px; */
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.scrollable-container .image-container {
  display: inline-block; /* Ensure image containers stay in a single line */
}

@media (min-width: 768px) {
  .scrollable-container {
    /* width: 800px; */
    overflow-x: hidden; /* Remove scrolling for larger screens */
    white-space: normal;
  }
}
.scroll-card {
  /* width: 100vw;
   */
  max-height: calc(100vh - 215px);
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 30px;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  padding-left: 10px;
  /* display: flex;
  align-items: center; */
}
.vision-box {
  border-radius: 15px;
  background-color: #fff;
  border: none;
  /* width: 1000px; */
  margin: 12px;
}
.vision-text-field {
  justify-content: center;
}

.vision-upload-card {
  width: 85px;
  background: #e9c5ff;
  color: black;
  font-size: 15px;
  height: 82px;
  text-align: center;
  margin-top: 3px;
  border-radius: 15px;
  cursor: pointer;
}
.download-icon-vision p {
  font-size: 17px !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.download-icon-text {
  font-size: 17px !important;
  color: #fff !important;
  font-weight: 600 !important;
  margin-bottom: 0.2rem !important;
}
.delete-icon-cursor {
  cursor: pointer;
}
.days-in-total-text {
  font-size: 15px;
}

.days-in-total-text span {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.challenge-day-in-total,
.challenge-date {
  color: #fff;
  font-weight: bold;
}
.habits-slider .MuiSlider-markLabel {
  left: 97%;
  font-size: 16px;
}

.habits-slider .MuiSlider-valueLabel {
  font-size: 16px;
}
.mui-slider .MuiSlider-valueLabelOpen {
  background-color: #fff;
  padding: 4px 10px;
  color: #9f00ff;
}
.mui-slider .MuiSlider-thumb {
  color: #fff;
  border: 1px solid var(--portal-theme-primary);
}

.mui-slider .MuiSlider-markLabel {
  color: #fff !important;
}

.mui-slider .MuiSlider-markLabel[data-index="0"] {
  left: 17px !important;
  right: auto;
}

.mui-slider .MuiSlider-markLabel[data-index="1"] {
  left: auto !important;
  right: -23px;
}
.slider-value {
  padding-top: 3px;
  margin-right: 10px;
}
.slider-value-detail {
  padding-top: 25px;
  margin-right: 10px;
}

.habits-gray-description {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 2px 0 rgba(81, 86, 248, 0.24),
    0 5px 10px -4px rgb(0 3 82 / 24%);
  background-color: #fff;
}
.team-member img {
  height: 110px;
  width: 122px;
  margin-right: 22px;
  object-fit: cover;
}
.note-date {
  font-weight: 600;
  font-size: 16px !important;
  color: #9f00ff !important;
}
.text-color-cards {
  color: #9f00ff;
}
.MuiSlider-mark {
  display: none;
}
.flex-column-on-small {
  /* Default flex-direction */
  flex-direction: row;
}

@media (max-width: 576px) {
  .flex-column-on-small {
    /* Change to column on small screens */
    flex-direction: column;
  }
}
/* for challenges slider at 100%
 .MuiSlider-valueLabelOpen {
  white-space: normal;
} */
.challenge-success {
  text-align: center;
  display: flex;
  margin-top: 5px;
  justify-content: center;
}
.challenge-success p {
  font-weight: 600;
}
.goal-icons .diary-icon-remove,
.goal-icons .diary-icon-add {
  width: 34px;
  height: 34px;
}
.goal-icons .diary-icon-remove {
  fill: red;
  color: red;
}
.goal-icons .diary-icon-add {
  fill: #9f00ff;
  color: #9f00ff;
}
.goal-icons {
  margin-left: 10px !important;
  /* margin-top: 40px !important; */
}
.goal-modal-theme {
  background-color: #ffee00 !important;
}
.custom-goal-field .MuiOutlinedInput-root {
  border-color: #000; /* Border color */
}

.custom-goal-field label.Mui-focused {
  color: #000; /* Label color on focus */
  border-color: #000 !important;
}
.custom-goal-field label.Mui-disabled {
  color: #000; /* Label color on focus */
  border-color: #000 !important;
}
.custom-goal-field label {
  color: #000; /* Label color on focus */
  border-color: #000 !important;
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.icon-button:hover {
  color: #007bff; /* Change to your preferred hover color */
}
.goal-icons {
  background-color: #9f00ff;
  color: #fff !important;
  margin-bottom: 10px;
}
.goal-icons:hover {
  background-color: #d899ff !important;
  color: #9f00ff !important;
  margin-bottom: 10px;
}
.goal-icons .MuiSvgIcon-root {
  color: #fff !important;
  fill: #fff !important;
}
.goal-icons .MuiSvgIcon-root:hover {
  color: #fff !important;
  fill: #fff !important;
}
.progress-card {
  padding: 35px 0px 20px 0px;
}
.step-label {
  cursor: pointer;
}
.custom-goal-field .MuiFormHelperText-filled {
  font-weight: 600 !important;
}
.date-div p {
  margin-right: -20px;
  font-size: 15px;
}
.MuiCheckbox-root.Mui-checked {
  margin-top: 5px;
  margin-right: 10px;
  opacity: 1;
}
/* .MuiCheckbox-root {
  margin-top: 5px;
  margin-right: 10px;
  opacity: 1;
  background-color: white;
  border-radius: 0px;
  border: none;
}
.MuiCheckbox-root:hover {
  background-color: white;
} */
.html-embed-resource {
  min-width: 1100px;
  text-align: center;
}
.embed_Code-recording h1,
.embed_Code-recording h2 {
  color: #000;
}
.books-detail-document {
  text-align: center;
}
.books-detail-document .other-resource {
  width: 100%;
  height: 600px;
}

.books-detail-document .loading-preview {
  height: 600px;
  width: 1000px;
  position: absolute;
  background-color: #9f00ff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book-detail-title {
  margin-left: 10px;
}
.custom-search-field .MuiOutlinedInput-input {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}
.card-title {
  color: #fff;
}
/* Flower animation */
@keyframes flowerAnimation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.flower {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  z-index: 10; /* Ensure it appears above other elements */
  animation: flowerAnimation 20s ease-out forwards; /* Flower animation */
}

/* .absolute-pdf-table .MuiTableCell-root,
.absolute-pdf-table .MuiTableCell-head {
  background-color: transparent !important;
} */
input[type="password"]::-ms-reveal {
  display: none;
}
.fBdFME {
  height: 500px !important;
}
.jblNHt {
  color: white !important;
}
